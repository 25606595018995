import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  section {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;

    h2 {
      font-size: 25px;
      font-weight: 500;
      color: rgb(69, 69, 69);
    }

    div {
      margin-top: 1rem;
    }

    button {
      width: 160px;
      background-color: #3a6ea5;
      height: 45px;
      border-radius: 8px;
      color: #fff;
      margin-top: 1.5rem;
      font-size: 20px;
    }
  }
`;
