import React from "react";
import { Container } from "./style";
import img501 from "../../../static/img/501.png";
import { useNavigate } from "react-router-dom";

function Page501() {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <Container>
      <section>
        <img src={img501} alt='501' width='400px' />
        <h2>
          Esta funcionalidade ainda não está disponível. <br /> Estamos
          trabalhando nisso!
        </h2>
        <div>
          <button onClick={handleGoBack}>Retornar</button>
        </div>
      </section>
    </Container>
  );
}

export default Page501;
